.navbar {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: white;
  box-shadow: #b5b5b5 0px -6px 10px;
  border-bottom: 1px solid #f3f3f3;
  z-index: 100;
}

.navbarspacer {
  height: 74px;
}

#logo {
  color: black;
  font-family: 'DM Serif Display', serif;
  font-size: 30px;
  text-decoration: none;
  margin: 10px 20px 5px;
  line-height: 20px;
}

#logo-subtitle {
  font-size: 16px;
  font-weight: bold;
}

.dropdown {
  display: inline-block;
  position: relative;
}

.navbutton {
  display: inline-flex;
  padding: 20px 20px 15px;
  vertical-align: bottom;
  align-items: center;
  color: #545454;
  font-family: 'Josefin Sans', sans-serif;
  font-size: 20px;
  background-color: white;
}

.navright {
  margin-left: auto;
  margin-right: 8px;
  cursor: pointer;
}

.navbutton:hover {
  text-decoration: underline;
}

.dropdownlist {
  position: absolute;
  width: 200px;
  background-color: white;
  overflow: hidden;
  border-color: #f3f3f3;
  border-style: solid;
  border-width: 0px 1px 1px 1px;
  box-shadow: black 0px 2px 5px -4px;
  transition: opacity 0.1s;
}

.dropdownbutton {
  padding: 8px 10px;
  font-family: 'Gabarito', sans-serif;
  font-size: 20px;
  color: black;
  background-color: white;
  border-left: 2px solid white;
  transition: background-color 0.2s, border-left 0.2s;
}

.dropdownbutton:hover {
  background-color: #f1fff9;
  border-left: 2px solid #072e1d;
}

.homepage {
  padding: 100px 175px;
}

.homepage > h1 {
  margin: 30px 0px;
  color: black;
  font-family: 'DM Serif Display', serif;
  font-size: 85px;
  font-weight: normal;
  line-height: 80px;
}

#searchbar {
  width: calc(100% - 180px);
  height: 50px;
  font-family: 'Josefin Sans', sans-serif;
  color: #545454;
  box-shadow: inset black 0 0 8px -5px;
  border-radius: 30px;
  border: 3px solid #97b497;
  padding: 0 20px;
  padding-top: 5px;
  font-size: 24px;
  transition: background-color 0.2s;
}

#searchbar:hover {
  background-color: #f1fff9;
}

#searchbar::placeholder {
  color: #bbb;
}

#searchbutton {
  display: inline-block;
  font-family: 'Josefin Sans', sans-serif;
  color: white;
  font-size: 24px;
  background-color: #072e1d;
  border: 2px solid #a4cfbc;
  border-radius: 30px;
  margin: 0 10px;
  padding: 15px 30px;
  padding-top: 20px;
  transition: color 0.2s, border 0.2s;
  cursor: pointer;
}

#searchbutton:hover {
  color: #2dffa5;
  border: 2px solid #2dffa5;
}

.sublink {
  display: inline-block;
  margin-top: 35px;
  margin-right: 30px;
  color: #545454;
  font-family: 'Josefin Sans', sans-serif;
  font-size: 20px;
  text-decoration: underline;
}

.flex-container {
  display: flex;
}
.flex-section {
  flex-grow: 1;
}

.heading {
  color: #303030;
  font-family: 'DM Serif Display', serif;
  font-size: 50px;
}

.article-container {
  border-top: 1px solid #eaedec;
  padding-top: 40px;
}